@import '../../styles/variables';

.slider-default {
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;

    .MuiBox-root {
        max-width: 100% !important;
        padding: 20px;
    }

    .MuiIconButton-root {
        background-color: rgba(255, 255, 255, 0.8) !important;
        color: $color-primary !important;
        transition: 0.2s;

        &:hover {
            background-color: rgba(255, 255, 255, 1) !important;
        }
    }

    .slider-swiper {
        height: 100%;

        .swiper-button-prev, .swiper-button-next {
            color: $color-secondary !important;
        }

        .swiper-pagination-bullet {
            background-color: rgba(255, 255, 255, 1);
        }

        .swiper-pagination-bullet-active {
            background-color: $color-secondary !important;
        }

        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: 0px !important;

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
            }
        }

        .slide-item {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 70vh;
            }
        }
    }


    .slider-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 6px 20px;
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
    }
}