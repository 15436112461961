
@import '../../../../styles/variables';


#products-section {
    .products-container {
        text-align: center;
        margin-top: 120px;

        &:first-child {
            margin-top: unset;
        }

        .category-name {
            font-size: 20px;
            font-weight: 600;
            color: $component-text-default-color-1;
            text-transform: uppercase;
        }

        .category-description {
            font-size: 16px;
            color: $component-text-default-color-2;
            margin-top: 12px;
        }

        .products-list {
            display: flex;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            flex-wrap: wrap;
            column-gap: 40px;
            row-gap: 60px;
            margin-top: 40px;

            .product-item {
                width: 20%;
                border-radius: $component-default-border-radius;
                box-shadow: $component-default-box-shadow;
                cursor: pointer;
                display: flex;
                flex-direction: column;

                .product-item-image {
                    border-radius: $component-default-border-radius;
                    width: 100%;
                    height: 200px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($color: $color-secondary, $alpha: 0.1);
                        opacity: 0;
                        transition: 0.2s;
                    }
                }

                .product-item-name {
                    display: block;
                    padding: 20px 12px;
                    font-size: 20px;
                    line-height: 22px;
                    color: $component-text-default-color-1;
                    transition: 0.2s;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                &:hover {
                    .product-item-image {
                        &::before {
                            opacity: 1;
                        }
                    }
        
                    .product-item-name {
                        color: $color-primary;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    #products-section {
        .products-container {
            .products-list {
                flex-wrap: nowrap;
                justify-content: flex-start;
                overflow: auto;
                padding-bottom: 20px;
    
                .product-item {
                    width: 100%;
                    min-width: 280px;
                }
            }
        }
    }
}