
@import '../../../../styles/variables';


#home-section {
    .wallpaper {
        &::before {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .video-container {
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            position: relative;

            .video-content {
                overflow: hidden;
                max-height: 100vh;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .wallpaper-watermark {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.5;

            img {
                width: 400px;
                max-width: 80%;
                height: auto;
            }
        }
    }

    .product-list {
        display: flex;
        justify-content: center;
        align-items: stretch;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 60px;

        .product-item {
            width: 20%;
            border-radius: $component-default-border-radius;
            box-shadow: $component-default-box-shadow;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            .product-item-image {
                border-radius: $component-default-border-radius;
                width: 100%;
                height: 200px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: $color-secondary, $alpha: 0.1);
                    opacity: 0;
                    transition: 0.2s;
                }
            }

            .product-item-name {
                display: block;
                padding: 20px 12px;
                font-size: 20px;
                line-height: 22px;
                color: $component-text-default-color-1;
                transition: 0.2s;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            &:hover {
                .product-item-image {
                    &::before {
                        opacity: 1;
                    }
                }
    
                .product-item-name {
                    color: $color-primary;
                }
            }
        }
    }

    .catalog-list {
        iframe {
            height: 600px;
        }
    }

    .container-aboutus {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;

        &::before {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    #home-section {
        .wallpaper {
            .video-container {
                inset: 0;
                height: 100%;
                padding-bottom: 0;
    
                .video-content {
                    inset: 0;
                    position: absolute;
    
                    iframe {
                        inset: 0;
                        width: calc(100vh * 7);
                        left: 50%;
                        transform: translateX(-50%);
                        object-fit: cover;
                    }
                }
            }
        }

        .product-list {
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow: auto;
            padding-bottom: 20px;

            .product-item {
                width: 100%;
                min-width: 280px;
            }
        }

        .catalog-list {
            iframe {
                height: 300px;
            }
        }
    }
}